<template>
  <div :class="s.wrapper">
    <top-head/>
    <!-- <spacer :y="4"/> -->
    <TopImages id="menu-trigger"/>

    <!-- お知らせ -->
    <SectionContainer :class="s.info" id="bg-trigger">
      <SectionFrame
        :title="'お知らせ'"
        :titleBottomMargin="46"
        :smTitleBottomMargin="21"
        :marginTop="-27"
        :smMarginTop="0"
        :titleSize="32"
        :smTitleSize="32"
        :titleOpacity="80">
        <InfoList :limit="5" />
        <Spacer :y="7"/>
        <FlexList :align="'center'">
          <basic-btn
            :tag="'a'"
            :link="'/news/'">もっと見る</basic-btn>
        </FlexList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer>
      <SectionFrame
        :marginTop="120"
        :smMarginTop="70">
        <a href="https://www.instagram.com/musubisumu_kiryu/" target="_blank">
          <img class="pc" src="/images/top/banner_event_pc.png" alt="">
          <img class="sp" src="/images/top/banner_event_sp.png" alt="">
        </a>
      </SectionFrame>
    </SectionContainer>

    <Spacer :y="6"/>

    <!-- 桐生を知る -->
    <div :class="s.about">
      <img :class="s.about_arc" :src="`/images/layout/top_arc.svg`" alt="">
      <div :class="s.about_gradation"/>
      <div :class="s.about_content">
        <div :class="s.about_title">桐生市を知る</div>
        <div
          :class="[s.about_detail]"
          class="n2br"
          v-html="'暮らす人、訪れる人\n商う人、働く人\n\nまちなかと自然\n織都1300年の伝統と新しい風\n感性豊かな糸と糸が、柔らかに交差し\n今日もまた、どこかで編み出されていく\n\nゆるやかに、つながり\nほどよく、むすばれる\n\nむすびすむ桐生へ'"/>
        <router-link to="/about/">
          <basic-btn
            :color="'wht'"
            :width="'fit-content'">詳しく見る</basic-btn>
        </router-link>
      </div>
      <div :class="s.about_img_wrap">
        <FlexList
          v-if="layout.sizeChecked"
          :spaceX="12"
          :spaceY="12"
          :count="6"
          :smCount="3"
          :wrap="true">
          <li v-for="(img, i) in aboutImages" :key="i">
            <div
              :class="s.about_img"
              :style="`background-image: url(${img});`"/>
          </li>
        </FlexList>
      </div>
    </div>

    <Spacer :y="30" :smY="18"/>

    <!-- 移住支援・サポート体制 -->
    <!-- <SectionContainer>
      <SectionFrame
        :title="'移住支援・サポート体制'"
        :marginTop="0"
        :maxWidth="1080"
        :titleOpacity="80"
        :titleBottomMargin="48">
        <div :class="s.flexbox">
          <div :class="[s.flexbox_imgs, s.horizontal, s.flexbox_content]">
            <div :class="s.img" :style="`background-image: url(/images/top_living_l.png);`"/>
            <div :class="s.img" :style="`background-image: url(/images/top_living_r.png);`"/>
          </div>
          <div :class="[s.flexbox_text_wrap, s.flexbox_content]">
            <Bg :bgcolor="'purple'">
              <div :class="s.flexbox_text">
                <div
                  :class="[s.flexbox_text_detail]"
                  class="n2br"
                  v-html="'今のアナタが\n『桐生に暮らす』を\n想像することができます。'"/>
                <Spacer :y="5"/>
                <router-link to="/shien/">
                  <basic-btn :width="'fit-content'">詳しく見る</basic-btn>
                </router-link>
              </div>
            </Bg>
          </div>
        </div>
      </SectionFrame>
    </SectionContainer>

    <Spacer :y="20" :smY="15"/> -->

    <!-- 店舗開業のススメ -->
    <!-- <SectionContainer>
      <SectionFrame
        :title="'店舗開業のススメ'"
        :maxWidth="1080"
        :marginTop="0"
        :titleOpacity="80"
        :titleBottomMargin="48">
        <div :class="[s.flexbox, s.sm_reverse]">
          <div :class="[s.flexbox_imgs, s.vertical, s.flexbox_content, s.flexbox_content]">
            <div :class="s.img" :style="`background-image: url(/images/top_business_t.png);`"/>
            <div :class="s.img" :style="`background-image: url(/images/top_business_b.png);`"/>
          </div>
          <div :class="[s.flexbox_text_wrap, s.flexbox_content]">
            <Bg :bgcolor="'purple'">
              <div :class="s.flexbox_text">
                <div
                  :class="[s.flexbox_text_detail]"
                  class="n2br"
                  v-html="'今のアナタが\n『桐生に暮らす』を\n想像することができます。'"/>
                <Spacer :y="5"/>
                <router-link to="/kaigyou/">
                  <basic-btn :width="'fit-content'">詳しく見る</basic-btn>
                </router-link>
              </div>
            </Bg>
          </div>
        </div>
      </SectionFrame>
    </SectionContainer>

    <Spacer :y="20"/> -->

    <SectionContainer v-if="pickups.length">
      <SectionFrame
        :title="'メニュー'"
        :maxWidth="1080"
        :marginTop="0"
        :smMarginTop="0"
        :titleOpacity="80"
        :titleBottomMargin="48">
        <FlexList
          :spaceX="24"
          :spaceY="64"
          :smSpaceY="32"
          :count="3"
          :smCount="1"
          :wrap="true"
          :align="pickups.length < 4 ? 'center' : 'left'"
          :class="[s.pickup, s.links]">
          <li v-for="(item, i) in pickups" :key="i">
            <a
              :href="item.link"
              :target="'_blank'">
              <ContentCard
                :img="item.medias.length ? item.medias[0].url : ''"
                :imgBorderRadius="28"
                :contentSidePadding="0"
                :title="item.title"
                :content="item.summary"></ContentCard>
            </a>
          </li>
        </FlexList>
      </SectionFrame>
    </SectionContainer>

    <Spacer :y="20" :smY="15"/>

    <Interview :category="categoryId"/>

    <SectionContainer id="simulation">
      <SectionFrame
        :title="'桐生暮らしシミュレーション'"
        :description="'桐生市に移住すると対象になり得る支援をシミュレーションしてみよう。'"
        :descriptionSize="'lg'"
        :marginTop="80">
        <SimulationBanners name="top" />
      </SectionFrame>
    </SectionContainer>

    <Spacer :y="20" :smY="15"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  BasicBtn,
  Spacer,
  SectionFrame,
  SectionContainer,
  TopImages,
  InfoList,
  FlexList,
  ContentCard,
  Interview,
  SimulationBanners,
} from '@/components/parts';
import TopHead from '@/components/layouts/TopHead.vue';

export default {
  components: {
    TopHead,
    BasicBtn,
    Spacer,
    SectionFrame,
    SectionContainer,
    TopImages,
    InfoList,
    FlexList,
    ContentCard,
    Interview,
    SimulationBanners,
  },
  data() {
    return {
      isSpView: false,
      akiyaUrls: {
        local: 'http://localhost:4088/',
        develop: 'https://dev.kiryu-akiya.jp',
        production: 'https://kiryu-akiya.jp',
      },
      aboutImages: [
        '/images/top/top_about_1.jpg',
        '/images/top/top_about_2.jpg',
        '/images/top/top_about_3.jpg',
        '/images/top/top_about_4.jpg',
        '/images/top/top_about_5.jpg',
        '/images/top/top_about_6.jpg',
      ],
      links: [
        {
          name: 'working',
          label: '働く',
          description: '店舗開業・起業、テレワークから就農まで、スタイルに合わせた多様な働く場所や仕事探しに関する情報を紹介します。',
          path: '/hataraku/',
          img: '/images/top/working.jpg',
        },
        {
          name: 'living',
          label: '住む',
          description: 'まちなかと自然が近く、両方の良いとこどりができる桐生市では、アクセスが良い駅近の物件から、緑に囲まれた一戸建ての物件まで、希望に合わせて選ぶことができます。ここでは物件探しや支援制度に関する情報をまとめています。',
          path: '/sumu/',
          img: '/images/top/living.jpg',
        },
        {
          name: 'raising',
          label: '育てる',
          description: '子育てに関する支援や、桐生ならではの教育プログラム、新たな学びの場からお出かけスポットまで、子育てに関する情報を紹介します。',
          path: '/kosodate/',
          img: '/images/top/raising.png',
        },
      ],
      pickups: [],
    };
  },
  computed: {
    ...mapState(['user', 'layout', 'helper']),
    akiyaLink() {
      const env = this.helper.env.name || 'production';
      return this.akiyaUrls[env];
    },
    categoryId() {
      return this.helper?.categories?.find((d) => d.name === 'top')?.id || null;
    },
  },
  created() {
    this.getPickups();
  },
  methods: {
    getPickups() {
      const params = {
        flags: [1],
        orderBy: 'order',
        order: 'asc',
      };

      this.axios({
        method: 'GET',
        url: '/v1/pickup/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.pickups = res.pickups.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.wrapper {
  width: 100%;
  overflow: hidden;
  min-height: 300px;
}
.info {
  position: relative;
  z-index: 1;
  margin-top: -14px;
}

.about {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: calc(100% * (398 / 1440));
  background-image: url('/images/top/top_about_bg.jpg');
  // background-image: url('/images/top/top_about_bg_origin.jpg');
  background-size: cover;
  &_gradation {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% * 304 / 1440);
    background: linear-gradient(0deg, #FFF 35.94%, rgba(255, 255, 255, 0.00) 100%);
  }
  &_arc {
    width: calc(100% * 1558 / 1440);
  }
  &_wht {
    position: absolute;
    width: 100%;
    height: calc(100% * (304 / 1440));
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, #FFF 35.94%, rgba(255, 255, 255, 0.00) 100%);
  }
  &_content {
    color: #ffffff;
    text-align: center;
    padding: 0 20px;
  }
  &_title {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 44px;
    text-shadow: 0px 0px 70px #00638d;
  }
  &_detail {
    font-size: 20px;
    line-height: 1.8;
    margin-bottom: 50px;
    font-family: 'Noto Sans JP';
    font-weight: 500;
    text-shadow: 0px 4px 20px #007d8d;
  }
  &_img {
    &_wrap {
      width: 100%;
      overflow: hidden;
      position: absolute;
      bottom: -(calc(100% * (80 / 1440)));
    }
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    background-position: center;
  }

  @include smView {
    &_content {
      padding-bottom: calc(100vw * 234 / 390);
    }
    &_title {
      margin-top: 80px;
      font-size: 32px;
    }
    &_detail {
      font-size: 16px;
    }
    &_gradation {
      height: calc(100vw * 304 / 390);
    }
  }
}

.flexbox {
  display: flex;
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
  justify-content: space-between;

  &.sm_reverse {
    flex-direction: row-reverse;
  }

  &_content {
    width: calc(50% - 24px);
  }

  &_imgs {
    display: flex;
    justify-content: space-between;
    .img {
      background-size: cover;
      background-position: center;
    }
    &.horizontal {
      .img {
        width: calc(50% - 4px);
        height: 100%;
      }
    }
    &.vertical {
      flex-flow: column;
      .img {
        height: calc(50% - 4px);
        width: 100%;
      }
    }
  }
  &_text {
    &_wrap {
      width: 50%;
    }
    padding: 108px 133px;
    display: flex;
    flex-flow: column;
    align-items: center;
    &_detail {
      text-align: center;
      line-height: 2;
      font-size: 22px;
      font-weight: 500;
    }
  }

  @include smView {
    display: block;

    &_content {
      width: 100%;
    }

    &_text {
      padding: 34px 67px;

      &_detail {
        font-size: 18px;
      }
    }

    &_imgs {
      padding-bottom: 8px;
      .img {
        padding-top: 200px;
      }
      &.vertical {
        flex-flow: inherit;
        .img {
          width: calc(50% - 4px);
        }
      }
    }
  }
}

.akiya {
  border-radius: 28px;
  padding: 42px 0;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 26px;
  background-size: cover;
  background-position: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    right: 24px;
    top: 50%;
    width: 28px;
    height: 28px;
    transform: translate(0, -50%);
    border-radius: 50%;
    background-color: #ffffff;
  }
  &::after {
    content: '';
    position: absolute;
    right: 34px;
    top: 50%;
    width: 10px;
    height: 10px;
    transform: translate(0, -50%) rotate(-45deg);
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
  }

  @include smView {
    font-size: 18px;
  }
}

.pickup {
  > li {
    width: 25%;
  }
}

.banners {
  padding: 80px 64px;
  .banner {
    width: 100%;
    padding-top: calc(100% * (144 / 420));
  }
}

.links {
  &_img {
    padding: 124px 0;
    text-align: center;
    border-radius: 28px;
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transition: all .3s;
  }
  &_content {
    font-weight: 500;
    margin-top: 16px;
    color: var(--label-primary);
  }
  a {
    transition: all .3s;
    &:hover {
      opacity: .6;
    }
  }
}
</style>
