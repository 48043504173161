<template>
  <div>
    <spacer :y="2"/>

    <SectionContainer id="charm">
      <SectionFrame
        :title="'桐生で店舗開業する魅力'"
        :marginTop="112"
        :smMarginTop="80"
        :maxWidth="1040">
        <FlexList
          :spaceX="40"
          :spaceY="32"
          :class="s.charm"
          :wrap="true"
          :count="4"
          :align="'center'"
          :smCount="1">
          <li v-for="(item, i) in charms" :key="i">
            <ContentCard
              :imgRatio="100"
              :smImgRatio="50"
              :imgBorderRadius="28"
              :img="item.media"
              :contentSidePadding="0"
              :fontSize="12"
              :title="item.label"
              :content="item.content">
            </ContentCard>
          </li>
        </FlexList>
        <Spacer :y="4"/>
        <FlexList :align="'center'">
          <li>
            <basic-btn
              :tag="'a'"
              :link="'/contact/'">移住コーディネーターに問い合わせてみる</basic-btn>
          </li>
        </FlexList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="topics">
      <SectionFrame
        :title="'お役立ちトピックス'"
        :description="'桐生市で「店舗開業・起業」のために役立つコンテンツをまとめ、随時更新しています。'"
        :descriptionSize="'lg'">
        <Bg :bgcolor="'gray'" :radius="28">
          <TopicList
            type="kaigyou"
            :limit="5" />
          <FlexList :align="'center'">
            <basic-btn
              :tag="'a'"
              :link="'/kaigyou/topics'"
              :color="'gry'">もっと見る</basic-btn>
          </FlexList>
          <spacer :y="4"/>
        </Bg>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="simulation">
      <SectionFrame
        :title="'桐生暮らしシミュレーション'"
        :description="'自分がこれから桐生市に移住する場合、どんな支援が対象になるのか、また自分の好みや条件だと、どんな桐生での暮らし方ができるのか、シミュレーションしてみましょう。'"
        :descriptionSize="'lg'">
        <SimulationBanners name="kaigyou"/>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="empty-shop">
      <SectionFrame
        :title="'空き店舗紹介'"
        :description="'桐生市の中心市街地の公開中の空き店舗情報の一部をご紹介します。\nタイミングによっては、ここに掲載していない物件情報がありますので、お気軽にお問い合わせください。'"
        :maxWidth="1080">
        <SimpleList
          :maxWidth="1080"
          :hasLine="false"
          :margin="32">
          <BgListItem
            :bgcolor="'gray'"
            :radius="28"
            :pdg="40"
            :smPdg="24"
            v-for="(empty, i) in empties"
            :key="i"
            :class="s.empty">
            <div :class="s.container">
              <div :class="s.title">{{ empty.label }}</div>
              <div :class="s.content">
                <div :class="s.imgs_wrap">
                  <FlexList
                    :spaceX="20"
                    :spaceY="20"
                    :wrap="['lg', 'md'].includes(layout.displaySize)"
                    :class="[s.imgs, s.pc_view, s.empty]">
                    <li v-for="media in empty.medias" :key="media.id">
                      <div
                        :class="s.img"
                        :style="`background-image: url(${media.url});`" />
                    </li>
                  </FlexList>
                </div>
                <div :class="s.info_wrap">
                  <FlexList
                    :spaceX="80"
                    :spaceY="24"
                    :smSpaceY="15"
                    :class="s.info"
                    :count="2"
                    :smCount="1"
                    :wrap="true">
                    <li v-for="(item, j) in emptiesItems" :key="j">
                      <div
                        :class="[
                          s.label,
                          n % 2 === 0 ? s.sm : '',
                        ]"
                        class="n2br"
                        v-html="item.label"/>
                      <div
                        :class="s.value"
                        class="n2br"
                        v-html="empty[item.name]"/>
                    </li>
                  </FlexList>
                  <div :class="s.full">
                      <div
                        :class="s.label" >交通機関</div>
                      <div class="n2br" :class="s.value" v-html="empty.access"></div>
                  </div>
                </div>
              </div>
              <Spacer :y="5"/>
              <FlexList
                :spaceX="16"
                :spaceY="16"
                :align="'center'"
                :wrap="true">
                <li><basic-btn :type="'bdr'" :link="empty.link" :tag="'a'" :openOtherTab="true">この空き店舗の詳しく見る</basic-btn></li>
                <li><basic-btn :link="'https://www.city.kiryu.lg.jp/sangyou/joho/akitenpo/ichiran/index.html'" :tag="'a'" :openOtherTab="true">その他の空き店舗を見る</basic-btn></li>
              </FlexList>
            </div>
          </BgListItem>
        </SimpleList>
        <Spacer :y="10"/>
        <div :class="s.map_wrap">
          <div :class="s.title">中心市街地空き店舗マップ（一部）</div>
          <div :class="s.note" class="n2br" v-html="'物件の最新の契約状況等については、\n不動産会社に直接ご確認いただくか、移住コーディネーターにお問い合わせください。'"/>
          <Spacer :y="5"/>
          <div :class="s.map">
            <iframe src="//www.google.com/maps/d/u/0/embed?mid=111Ame2Jha7_cu4sf-FQQCbB77L_EszI&ehbc=2E312F&z=15&ll=36.4111158,139.3332032" width="100%" height="480"></iframe>
          </div>
          <Spacer :y="6"/>
          <FlexList :align="'center'">
            <li><basic-btn :tag="'a'" :link="'/contact/'">移住コーディネーターに問い合わせてみる</basic-btn></li>
          </FlexList>
        </div>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="organization">
      <SectionFrame
        :title="'目的に合わせた支援機関'">
        <div :class="s.contact">
          <div>店舗開業や起業を検討する場合に、各種支援機関に相談が可能です。<br>起業·創業に関する支援内容など、全般的な相談をしたい方はまずはこちらにお問い合わせください。</div>
          <spacer :y="3"/>
          <div :class="s.contact_name">桐生市商工振興課商業金融担当</div>
          <div>（電話0277-32-4104）</div>
        </div>
        <spacer :y="5"/>
        <SimpleList
          :hasLine="false"
          :margin="24"
          v-if="card.cards?.length">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'organizations')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <SectionContainer id="support">
      <SectionFrame
        :title="'店舗開業・起業に関する支援制度'"
        :description="'店舗開業・起業に関して、様々な支援制度があります。\nここでは一部を紹介しますので、活用方法などについて、お気軽にお問い合わせください。'">
        <SimpleList
          :hasLine="false"
          :margin="32"
          v-if="card.cards?.length">
          <BgListItem
            v-for="item in adjustCards(card.cards, 'supports')"
            :key="item"
            :bgcolor="'gray'">
              <ContentListFrame :item="item" />
          </BgListItem>
        </SimpleList>
      </SectionFrame>
    </SectionContainer>

    <!-- 第２弾以降 -->
    <!-- <SectionContainer id="support">
      <SectionFrame
        :title="'支援機関インタビュー'"
        :description="'支援機関の担当者にお話をうかがいました。'"
        :maxWidth="1080">
        <FlexList :spaceX="24" :count="3">
          <li
            v-for="n of 3"
            :key="n">
            <ContentCard
              :class="s.card"
              :radius="28"
              :img="'/images/interview.png'"
              :imgRatio="70"
              :date="'2023.10.10'"
              :name="'今氏かずみち さん'"
              :content="'あのイーハトーヴォのすきとおった風、夏でも底に冷たさをもつ青いそら、うつくしい森で飾られたモリーオ市、郊外のぎらぎらひかる草の波'"
              :contentBg="'wht'">
              <spacer :y="1"/>
              <tag-btn
                :size="'sm'"
                :type="'category'">カテゴリー名</tag-btn>
            </ContentCard>
          </li>
        </FlexList>
      </SectionFrame>
    </SectionContainer> -->

    <spacer :y="7.5"/>

    <spacer :y="12.5"  id="interview"/>
    <Interview
      :category="categoryId" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Spacer,
  SectionFrame,
  BasicBtn,
  ContentListFrame,
  Bg,
  SectionContainer,
  SimpleList,
  FlexList,
  ContentCard,
  Interview,
  TopicList,
  BgListItem,
  SimulationBanners,
} from '@/components/parts';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'support-living',
  components: {
    Spacer,
    BgListItem,
    SectionFrame,
    BasicBtn,
    ContentListFrame,
    Bg,
    SectionContainer,
    SimpleList,
    FlexList,
    ContentCard,
    Interview,
    TopicList,
    SimulationBanners,
  },
  mixins: [cf],
  data() {
    return {
      charms: [
        {
          label: '充実の支援機関・助成制度',
          content: '店舗開業や起業に向けたアドバイスなど、充実の支援機関で相談ができます。空き店舗を活用した創業に関する支援制度など、店舗開業・創業者を応援する充実した支援メニューがあります。 ',
          media: '/images/detail/kaigyou_charm_1.jpg',
        },
        {
          label: 'こだわり・想いが活かせる',
          content: '織物や繊維のまちとして盛えた歴史から、『ものづくり』の職人気質なDNAが今もまちに根付いています。そのため、こだわりや熱い想いを持った個人店舗がたくさんあります。',
          media: '/images/detail/kaigyou_charm_2.jpg',
        },
        {
          label: '店舗間のゆるやかなつながり',
          content: '店舗・事業者には、組織・団体や業種・業界を越えたゆるやかなつながりがあり、お互いにお客さんを紹介し合っています。店舗開業する前から店舗間のコミュニケーションがあり、関係性づくりがしやすい環境です。',
          media: '/images/detail/kaigyou_charm_3.jpg',
        },
      ],
      emptiesItems: [
        {
          label: '所在地',
          name: 'address',
        },
        {
          label: '賃貸部分',
          name: 'part',
        },
        {
          label: '面積',
          name: 'area',
        },
        {
          label: '賃料\n（管理費・共益費）',
          name: 'price',
        },
        {
          label: '希望業種',
          name: 'industry',
        },
        {
          label: '備考',
          name: 'remark',
        },
      ],
      empties: [],
      card: {
        loading: true,
        notFound: false,
        cards: [],
      },
    };
  },
  created() {
    this.getProperty();
    this.getCards();
  },
  computed: {
    ...mapState(['layout', 'helper']),
    type() {
      return this.$route.meta.name || null;
    },
    categoryId() {
      return this.helper?.categories?.find((d) => d.name === this.type)?.id || null;
    },
  },
  methods: {
    getProperty() {
      const params = {};

      this.axios({
        method: 'GET',
        url: '/v1/property/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.empties = res.properties.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
    getCards() {
      const params = {
        flags: [1],
        type: this.type,
        orderBy: 'order',
        order: 'asc',
      };

      this.axios({
        method: 'GET',
        url: '/v1/card/get/list',
        params,
      })
        .then((response) => {
          this.card.cards = response.data.cards.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          console.log(error);
          this.card.notFound = true;
        })
        .finally(() => {
          this.card.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" module="s">
.charm {
  > li {
    width: 25%;
  }
}

.empty {
  .title {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }
  .content {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 32px;
  }
  .info {
    &_wrap {
      width: 55%;
      font-size: 12px;
      .full {
        display: flex;
        margin-top: 24px;
      }
      .label {
        width: 87px;
        font-weight: 700;
        border-right: 1px solid #000000;
        padding: 6.5px 12px 6.5px 0;
        text-align: end;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &.sm {
          width: 50px;
        }
      }
      .value {
        flex: 1;
        padding: 6.5px 0 6.5px 12px;
      }
    }
    > li {
      > * {
        display: flex;
        align-items: center;
      }
      display: flex;
      align-items: stretch;
    }
  }

  .imgs {
    &_wrap {
      width: 45%;
      margin-left: 80px;
    }
    > li {
      width: 50%;
    }
    .img {
      width: 100%;
      padding-top: 100%;
      background-size: cover;
      background-position: center;
      border-radius: 14px;
    }
  }
}

.map {
  &_wrap {
    max-width: 800px;
    margin: 0 auto;
    .title {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
    }
    .note {
      margin-top: 24px;
      text-align: center;
    }
  }
  height: 480px;
  display: block;
  border-radius: 28px;
  border: 2px solid var(--border-point-gray);
  overflow: hidden;
}

.contact {
  text-align: center;
  &_name {
    font-weight: 700;
  }
}

.card {
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
}

@include smView {
  .empty {
    .content {
      display: block;
      > * {
        width: 100%;
      }
      .imgs {
        &_wrap {
          overflow-x: scroll;
          overflow-y: hidden;
          margin-left: 0;
        }
        width: fit-content;
        > li {
          width: 200px;
        }
      }
      .info {
        &_wrap {
          margin-top: 24px;
          .label {
            width: 68px;
            &.sm {
              width: 68px;
            }
          }
        }
      }
    }
  }
}
</style>
